<template>
    <table v-bind="$attrs">
      <thead>
        <tr>
          <th>
            <company-letter-head class="pb-5"/>
            <v-divider  />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <!-- Page body header -->
          </td>
        </tr>
        <tr>
          <td>
            <!-- Page body -->
          </td>
        </tr>
        <tr>
          <td>
            <div style="height: 20px;" />
            <v-divider/>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <!-- Page Footer -->
          </td>
        </tr>
      </tfoot>
    </table>
</template>

<script>
export default {

}
</script>

<style scoped>

@page {
  margin: 20mm;
}

@media print {
  
}
</style>
